import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { bool, func, number, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import {
  Avatar,
  IconNotification,
  LanguageSelect,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  NamedLink,
} from '..';
import { propTypes } from '../../util/types';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';

import css from './BottomNavigation.module.css';

const BLACKLISTED_PAGES = ['ListingPage'];

const BottomNavigation = props => {
  const {
    rootClassName,
    className,
    currentUser,
    notificationCount,
    messageNotificationCount,
    isAuthenticated,
    onLogout,
    currentUserHasListings,
    currentPage,
    authInProgress,
    location,
    isHidden,
    listing,
  } = props;

  const [mounted, setMounted] = useState(false);

  const isAuthenticatedOrJustHydrated = useMemo(() => isAuthenticated || !mounted, [
    isAuthenticated,
    mounted,
  ]);

  const classes = useMemo(
    () =>
      classNames(rootClassName || css.root, className, {
        [css.hideOnTablet]: currentPage === 'EditListingPage',
        [css.hideNav]: BLACKLISTED_PAGES.includes(currentPage) || isHidden,
      }),
    [rootClassName, className, currentPage, isHidden]
  );

  const notificationDot = useMemo(
    () =>
      notificationCount > 0 || messageNotificationCount ? (
        <IconNotification className={css.notificationDot} />
      ) : null,
    [notificationCount, messageNotificationCount]
  );

  const currentPageClass = useCallback(
    page => {
      const isAccountSettingsPage =
        page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
      return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
    },
    [currentPage]
  );

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div className={classes}>
      <div className={css.leftSide}>
        <LanguageSelect
          className={css.marginRight}
          location={location}
          placeBottom
          listing={listing}
        />
      </div>

      <div className={css.buttonWrapper}>
        <NamedLink
          className={classNames(css.addActivityButton, {
            [css.hideLink]: currentPage === 'EditListingPage',
          })}
          name={isAuthenticated ? 'NewListingPage' : 'SignupPage'}
        >
          {isAuthenticated ? (
            <FormattedMessage id="General.addActivity" />
          ) : (
            <FormattedMessage id="General.signUp" />
          )}
        </NamedLink>
      </div>

      <div className={css.rightSide}>
        <Menu className={css.menuPofile}>
          <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
            <Avatar
              className={classNames(css.avatar, css.marketplaceColorAvatar)}
              user={currentUser}
              disableProfileLink
            />
            {notificationDot}
          </MenuLabel>
          <MenuContent className={css.profileMenuContent} style={{ width: '100vw', left: '0' }}>
            {!isAuthenticatedOrJustHydrated ? (
              <>
                <MenuItem key="SignupPage">
                  <NamedLink
                    className={classNames(css.profileSettingsLink, currentPageClass('SignupPage'))}
                    name="SignupPage"
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="BottomNavigation.signup" />
                  </NamedLink>
                </MenuItem>
                <MenuItem key="LoginPage">
                  <NamedLink
                    className={classNames(css.yourListingsLink, currentPageClass('LoginPage'))}
                    name="LoginPage"
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="BottomNavigation.login" />
                  </NamedLink>
                </MenuItem>
                <MenuItem key="HelpCenterBasePage">
                  <NamedLink
                    className={classNames(
                      css.yourListingsLink,
                      css.itemGap,
                      currentPageClass('HelpCenterBasePage')
                    )}
                    name="HelpCenterBasePage"
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="General.help" />
                  </NamedLink>
                </MenuItem>
                <MenuItem key="addActivity">
                  <NamedLink
                    className={classNames(css.yourListingsLink, css.boldItem, css.itemGap)}
                    name="NewListingPage"
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="General.addActivity" />
                  </NamedLink>
                </MenuItem>
              </>
            ) : (
              <>
                <MenuItem key="AccountSettingsPage">
                  <NamedLink
                    className={classNames(
                      css.yourListingsLink,
                      currentPageClass('AccountSettingsPage')
                    )}
                    name="AccountSettingsPage"
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="BottomNavigation.accountSettingsLink" />
                  </NamedLink>
                </MenuItem>

                <MenuItem key="MessagesPage">
                  <NamedLink
                    className={classNames(css.yourListingsLink, currentPageClass('MessagesPage'))}
                    name="MessagesPage"
                  >
                    <span className={css.menuItemBorder} />
                    <span>
                      {!!messageNotificationCount && (
                        <IconNotification className={css.notificationDotMobileMenu} />
                      )}
                      <FormattedMessage id="BottomNavigation.messages" />
                    </span>
                  </NamedLink>
                </MenuItem>

                <MenuItem key="BookingsPage">
                  <NamedLink
                    className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
                    name="InboxPage"
                    params={{ tab: currentUserHasListings ? 'sales' : 'orders', type: 'upcoming' }}
                  >
                    <span className={css.menuItemBorder} />
                    <span>
                      {notificationCount > 0 && (
                        <IconNotification className={css.notificationDotMobileMenu} />
                      )}
                      <FormattedMessage id="BottomNavigation.bookings" />
                    </span>
                  </NamedLink>
                </MenuItem>

                <MenuItem key="ListingsPage">
                  <NamedLink
                    className={classNames(
                      css.yourListingsLink,
                      currentPageClass('ManageListingsPage')
                    )}
                    name="ManageListingsPage"
                    params={{ tab: 'published' }}
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="BottomNavigation.listings" />
                  </NamedLink>
                </MenuItem>

                <MenuItem key="HelpCenterBasePage">
                  <NamedLink
                    className={classNames(
                      css.yourListingsLink,
                      css.itemGap,
                      currentPageClass('HelpCenterBasePage')
                    )}
                    name="HelpCenterBasePage"
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="General.help" />
                  </NamedLink>
                </MenuItem>

                <MenuItem key="logout">
                  <button
                    type="button"
                    className={classNames(css.logoutButton, css.yourListingsLink)}
                    onClick={onLogout}
                    disabled={authInProgress}
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="BottomNavigation.logout" />
                  </button>
                </MenuItem>

                <MenuItem key="addActivity">
                  <NamedLink
                    className={classNames(css.yourListingsLink, css.boldItem, css.itemGap)}
                    name="NewListingPage"
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="General.addActivity" />
                  </NamedLink>
                </MenuItem>
              </>
            )}
          </MenuContent>
        </Menu>
      </div>
    </div>
  );
};

BottomNavigation.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  messageNotificationCount: 0,
  currentUserListing: null,
  currentUserListingFetched: false,
  isHidden: false,
};

BottomNavigation.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  messageNotificationCount: number,
  isHidden: bool,
};

export default BottomNavigation;
