import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Menu, MenuContent, MenuLabel, MenuItem } from '..';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { EnglishFlag, ItalianFlag, GermanFlag, FrenchFlag } from '..';
import { LANGUAGE_CODES, LOCALE_CODE } from '../../constants/locales';

import css from './LanguageSelect.module.css';
import useInterfaceLang from '../../hooks/useInterfaceLang';
import moment from 'moment';
import { createURLParamsForListing } from '../../util/listingHelpers';
import { localizeListingPagePath } from '../../util/routes';

const getLanguageFlag = langCode => {
  switch (langCode) {
    case LOCALE_CODE.EN_US:
      return EnglishFlag;
    case LOCALE_CODE.FR_FR:
      return FrenchFlag;
    case LOCALE_CODE.DE_DE:
      return GermanFlag;
    case LOCALE_CODE.IT_IT:
      return ItalianFlag;
    default:
      break;
  }
};

const getLanguageFullName = langCode => {
  switch (langCode) {
    case LOCALE_CODE.EN_US:
      return 'English';
    case LOCALE_CODE.FR_FR:
      return 'Français';
    case LOCALE_CODE.DE_DE:
      return 'Deutsch';
    case LOCALE_CODE.IT_IT:
      return 'Italiano';
    default:
      break;
  }
};

const LanguageSelect = props => {
  const { className, rootClassName, placeBottom, location, embedded, listing } = props;

  const { interfaceLang, changeLanguage } = useInterfaceLang();

  const FlagSelected = useMemo(() => getLanguageFlag(interfaceLang), [interfaceLang]);
  const langSelected = useMemo(() => interfaceLang.split('-')[0], [interfaceLang]);

  const classes = useMemo(
    () => ({
      menu: classNames(rootClassName || css.root, { [css.rootBottomNav]: placeBottom }, className),
      menuLabel: classNames(css.menuLabel, { [css.menuLabelBottomNav]: placeBottom }),
      contextLabel: classNames(css.contentLabel, { [css.contentLabelBottomNav]: placeBottom }),
      labelIcon: classNames(css.labelIcon, { [css.labelIconBottomNav]: placeBottom }),
      menuContent: classNames(css.menuContent, { [css.menuContentBottomNav]: placeBottom }),
      menuItemBord: classNames(css.menuItemBorder, { [css.menuItemBorderBottomNav]: placeBottom }),
      menuItemText: classNames(css.menuItemText, { [css.menuItemTextBottomNav]: placeBottom }),
      menuItemIcon: classNames(css.menuItemIcon, { [css.menuItemIconBottomNav]: placeBottom }),
    }),
    [className, placeBottom, rootClassName]
  );

  const isCMSPage = location.pathname.startsWith(`/${interfaceLang}/p/`);

  const menuContentStyleMaybe = useMemo(
    () => (placeBottom ? { style: { width: '100vw', left: '0' } } : {}),
    [placeBottom]
  );

  if (embedded) {
    return (
      <div className={css.rootEmbedded}>
        {LANGUAGE_CODES.map(languageCode => {
          const Flag = getLanguageFlag(languageCode);
          const language = getLanguageFullName(languageCode);
          const pathWithLang = location.pathname.replace(interfaceLang, languageCode);

          let pathname = isCMSPage
            ? pathWithLang.replace(/\/p\/\w{2}-/, `/p/${languageCode.split('-')[0]}-`)
            : pathWithLang;

          const isActive = languageCode === interfaceLang;

          if (listing) {
            pathname = localizeListingPagePath(
              pathname,
              languageCode,
              createURLParamsForListing(listing, languageCode)
            );
          }

          return (
            <Link
              key={languageCode}
              className={classNames(css.embeddedItem, { [css.active]: isActive })}
              onClick={e => {
                if (isActive) {
                  e.preventDefault();

                  return;
                }

                moment.locale(languageCode);
                changeLanguage(languageCode);
              }}
              to={{ ...location, pathname }}
            >
              <span className={classes.menuItemBord} />
              <span className={classes.menuItemText}>{language}</span>
              <Flag className={classes.menuItemIcon} />
            </Link>
          );
        })}
      </div>
    );
  }

  return (
    <Menu className={classes.menu}>
      <MenuLabel className={classes.menuLabel}>
        <span className={classes.contextLabel}>
          <FlagSelected className={classes.labelIcon} />
          {!placeBottom && <span>{langSelected}</span>}
        </span>
      </MenuLabel>
      <MenuContent className={classes.menuContent} {...menuContentStyleMaybe}>
        {LANGUAGE_CODES.map(languageCode => {
          const Flag = getLanguageFlag(languageCode);
          const language = getLanguageFullName(languageCode);
          const pathWithLang = location.pathname.replace(interfaceLang, languageCode);

          let pathname = isCMSPage
            ? pathWithLang.replace(/\/p\/\w{2}-/, `/p/${languageCode.split('-')[0]}-`)
            : pathWithLang;

          if (listing) {
            pathname = localizeListingPagePath(
              pathname,
              languageCode,
              createURLParamsForListing(listing, languageCode)
            );
          }

          const isActive = languageCode === interfaceLang;

          return (
            <MenuItem key={languageCode}>
              <Link
                className={classNames(css.menuItem, { [css.active]: isActive })}
                onClick={e => {
                  if (isActive) {
                    e.preventDefault();
                    return;
                  }

                  moment.locale(languageCode);
                  changeLanguage(languageCode);
                }}
                to={{ ...location, pathname }}
              >
                <span className={classes.menuItemBord} />
                <span className={classes.menuItemText}>{language}</span>
                <Flag className={classes.menuItemIcon} />
              </Link>
            </MenuItem>
          );
        })}
      </MenuContent>
    </Menu>
  );
};

LanguageSelect.defaultProps = {
  className: null,
  rootClassName: '',
  placeBottom: false,
  embedded: false,
};

LanguageSelect.propTypes = {
  className: string,
  rootClassName: string,

  placeBottom: bool,
  embedded: bool,
};

export default LanguageSelect;
